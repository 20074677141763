import { Dispatch, Ref, SetStateAction, ReactChild, ReactChildren } from 'react';

export type FormSchema<StepNumberType extends number> = {
	[Key in StepNumberType]?: { header?: JSX.Element; body: JSX.Element };
};

// pre-defined country value for Patient entity
export enum Country {
  CAN = 'ca',
  USA = 'us',
}
// pre-defined insurance_type value for Insurance entity
export enum InsuranceType {
  AB = 'ahcip',
  BC = 'bchip',
  ON = 'ohip',
  NB = 'nbm',
}
// pre-defined form identifier for APIV2
export enum FormId {
  AB = 'ab',
  BC = 'bc',
  ON = 'on',
  CALI = 'cali',
  T365 = 't365',
}

export enum DateTemplate {
  SLASH = 'M/D/YYYY',
  DASH_TIMESTAMP = 'YYYY-MM-DD HH:mm:ss',
}

export enum DateTimeZone {
  EST = 'America/Toronto',
}

export enum UtmParameter {
  utmMedium = 'utmSource',
  utmSource = 'utmMedium',
  utmCampaign = 'utmCampaign',
}
// type for group of UtmParameters
type UtmParameters = { [key in keyof typeof UtmParameter]: string | string[] };

type GoogleSheetCell =
  | { date: { id?: FormInputId; template: DateTemplate; timeZone?: DateTimeZone } }
  | { empty: number }
  | FormInputId
  | UtmParameter;

export interface GoogleSheetConfig {
  id: string;
  tabAndRange: string;
  columnOrder: ({ cell: GoogleSheetCell } | { groupedCell: [string, GoogleSheetCell][] })[];
}

export interface AppointmentsApiSchema {
  formInfo: {
    id: FormId;
    urlParameters?: Partial<UtmParameters>;
    country: Country;
    insuranceType?: InsuranceType;
  };
  integrations: {
    slack: { webHook: string };
    sendGrid: { templateId: string };
    googleSheets: GoogleSheetConfig;
  };
  patientInput: PatientFormModel;
}

export interface BookingSpecification {
  formSchema: FormSchema<number>;
  apiSchema: Partial<AppointmentsApiSchema>;
}

export interface ErrorsModel {
  requestError: boolean;
  userErrors: number;
}

export interface TopLevelBookingHooks {
  parentHooks: {
    errorsStateHook: [ErrorsModel, Dispatch<SetStateAction<ErrorsModel>>];
  };
}

export enum FormInputId {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
  insuranceNumber = 'insuranceNumber',
  versionCode = 'versionCode',
  dob = 'dob',
  expiresOn = 'expiresOn',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  state = 'state',
  postalCode = 'postalCode',
  chiefComplaint = 'chiefComplaint',
  termsAndConditions = 'termsAndConditions',
  devicesProgram = 'devicesProgram',
  promotionalMessages = 'promotionalMessages',
}

// type for group of FormInputIds
type FormInputIds = { [key in keyof typeof FormInputId]: string };

export interface PatientFormModel {
  [key: number]: Partial<FormInputIds>;
}

export enum Step {
  ContactInfo = 0,
  PersonalInfo,
  MedicalConditions,
  OutOfForm,
}

export default interface ChildrenProps {
  children?: ReactChild | ReactChildren;
  className?: string;
}

export interface FactoryProps extends ChildrenProps {
  schema: FormSchema<Step>;
  step: Step;
}

export interface InsuranceTypeLabelProps {
  insuranceTypeLabel: string;
}

export enum ReactHookFormErrorKey {
  Required = 'required',
  Pattern = 'pattern',
}

export enum ValidationType {
  Required = 0,
  RequiredAndShared = 1,
  RequiredAndPattern = 2,
  Undefined = 3,
}

interface InputAttributes {
  id: string;
  type?: string;
  required?: boolean;
  pattern?: RegExp;
  min?: number;
  mask?: {
    maskPattern: (string | RegExp)[];
    textMask: string;
  };
  autoComplete?: {
    options: { title: string }[];
  };
}

export interface InputProps {
  field: InputAttributes;
  register?: ({ }) => Ref<any>;
  error?: boolean;
}

export interface Patient {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  gender: string;
  sex: string;
  pronouns: string;
  dob: Date;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  pcProviderName: string;
  externalId: string;
  lastExternalUpdate: Date;
  stripeCustomerId: string;
  createdAt: Date;
  updatedAt: Date;
  insurances?: Insurance[];
  preferredPharmacy?: Pharmacy;
}

export interface Insurance {
  id: number;
  insuranceNumber: string;
  versionCode?: string;
  ohipStatus?: boolean;
  ohipStatusAction?: string;
  insuranceType: string;
  issuedOn?: Date;
  expiresOn?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface Pharmacy {
  id: number;
  store_number: number;
  name: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  store_phone_number?: string;
  rx_phone_number?: string;
  fax_number: string;
  latitude: string;
  longitude: string;
  is_sponsored: boolean;
  distance_in_mi: number;
  distance_in_km: number;
  created_at: Date;
  updated_at: Date;
}

export enum ToastSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export interface ToastState {
  open: boolean;
  severity: ToastSeverity;
  text: string;
}

export interface AppointmentTimeSlot {
  start: string;
}

export interface Appointment {
  id: number;
  user: string;
  patientId: number;
  dateTime: Date;
  chiefComplaint: string;
  appointmentType: string;
  viewerId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  timeSlot: AppointmentTimeSlot;
}

interface User {
  fullTitleName: string;
}

export interface fileUpload {
  id: number;
  shortUrl: string;
  fileType: string;
  fileName: string;
  destinationPath: string;
  boxUploadId: string;
  boxDownloadUrl: string;
  boxEmbedUrl: string;
  localFilePath: string;
  user?: User;
  createdAt: Date;
  updatedAt: Date;
}

export interface Document {
  id: number;
  fileUpload: fileUpload;
  formType: string;
  formOptions: object;
  paymentCode: string;
  externalPatientId: string;
  externalEncounterId: string;
  externalProviderId: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum FormType {
  MANUAL_DOCTORS_NOTE = `Doctor's Note`,
  MANUAL_CONSULTATION_LETTER = 'Consulation Lettter',
  MANUAL_IMAGE_OF_RASH = 'Image of Rash',
  MANUAL_IMAGE_OF_INJURY = 'Image of injury',
  MANUAL_DEVICE_IMAGE = 'Device Image',
  MANUAL_DEVICE_RECORDING = 'Device Recording',
  MANUAL_LAB_RESULT = 'Lab Result',
  MANUAL_FORM = 'Form',
  MANUAL_OTHER = 'other',
}

export interface AppointmentTime {
  start: Date;
  timeSlotId: string;
}

export enum AppointmentLocation {
  ONTARIO = 'Ontario',
  ALBERTA = 'Alberta',
  BRITISH_COLUMBIA = 'British Columbia',
  CALIFORNIA = 'California',
}

export enum AppointmentStatus {
  BOOKED = 'booked',
  RESERVED = 'reserved',
  RD_CANCELED = 'rd_canceled',
  DR_CANCELLED = 'dr_cancelled',
  PT_CANCELLED = 'pt_cancelled',
}

export enum AppointmentParticipantType {
  PATIENT = 'patient',
}

export const GENDERS = {
  male: 'Male',
  female: 'Female',
  non_binary: 'Non-binary',
  agender: 'Agender',
  bigender: 'Bigender',
  genderqueer: 'Genderqueer',
  gender_non_conforming: 'Gender Non-conforming',
  two_spirited: 'Two-spirited',
} as const;

export const SEX = {
  male: 'Male',
  female: 'Female',
  intersex: 'Intersex',
  prefer_not_say: 'Prefer not to say',
} as const;

export const PRONOUNS = {
  he_him: 'He/Him',
  she_her: 'She/Her',
  they_them: 'They/Them',
} as const;

export const PROVINCE = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
} as const;

export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  DC: 'Washington, DC',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
} as const;

export enum insuranceTypeCodeCA {
  MSP = 'MSP',
  OHIP = 'OHIP',
  ACHIP = 'ACHIP',
  NBM = 'NBM',
}

export const insuranceTypeCAN = {
  [insuranceTypeCodeCA.ACHIP]: 'Alberta Health Care Insurance Plan (ACHIP)',
  [insuranceTypeCodeCA.MSP]: 'British Columbia Medical Services Plan (MSP)',
  [insuranceTypeCodeCA.OHIP]: 'Ontario Health Insurance Plan (OHIP)',
  [insuranceTypeCodeCA.NBM]: 'New Brunswick Medicare (NBM)', 
} as const;

export const insuranceTypeUSA = [
  { value: 'cash', label: 'Cash / Paying for Self' },
  { value: 'medicare', label: 'Medicare' },
  { value: 'medi-cal', label: 'Medi-Cal' },
  { value: 'aetna', label: 'Aetna' },
  { value: 'anthem', label: 'Anthem' },
  { value: 'blueShield-california', label: 'Blueshield California' },
  { value: 'blueCross-blueShield', label: 'Bluecross - Blueshield' },
  { value: 'california-choice', label: 'California Choice' },
  { value: 'cigna', label: 'Cigna' },
  { value: 'chinese-community-ealth-lan', label: 'Chinese Community Earth Lan' },
  { value: `citizens-choice`, label: 'Citizens Choice' },
  { value: 'first-health', label: 'First Health' },
  { value: 'HCSC', label: 'HCSC' },
  { value: 'health-net', label: 'Health Net' },
  { value: 'humana', label: 'Humana' },
  { value: 'la-care-health-plan', label: 'L.A. Care Health Plan' },
  { value: 'meritain-health', label: 'Meritain Health' },
  { value: 'mcclatchy-livewell', label: 'Mcclatchy Livewell' },
  { value: 'mcna-health-care', label: 'MCNA Health Care' },
  { value: 'molina-healthcare', label: 'Molina Healthcare' },
  { value: 'nippon-life-benefits', label: 'Nippon Life Benefits' },
  { value: 'pacificSource', label: 'Pacific Source' },
  { value: 'sharp-health-plan', label: 'Sharp Health Plan' },
  { value: 'the-health-plan', label: 'The Health Plan' },
  { value: 'tricare', label: 'Tricare' },
  { value: 'ucare', label: 'UCare' },
  { value: 'umr', label: 'UMR' },
  { value: 'united-healthcare', label: 'United Healthcare' },
  { value: 'valley-health-plan', label: 'Valley Health Plan' },
  { value: 'other', label: 'Other' },
];
