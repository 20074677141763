import { makeStyles } from '@mui/styles';
import LockIcon from '@mui/icons-material/Lock';
import LoadingButton from '@mui/lab/LoadingButton';
import { createRoute } from '@tanstack/react-router';
import { useState, useEffect, useCallback } from 'react';
import { Typography, TextField, FormControl, Link, Box } from '@mui/material';

import { post } from '../../lib/api/_helper.ts';
import { validateEmail } from '../../lib/_helpers.ts';
import RDLogoLight from '../../components/Common/RdLogoLight.tsx';
import { rootRoute } from '../../router/rootRoute.tsx';

export const passwordForgotRoute = createRoute({
	component: PasswordForgot,
	path: '/password-forgot',
	getParentRoute: () => rootRoute,
});

const useStyles = makeStyles(() => ({
	logo: { marginTop: 50, textAlign: 'center' },
	textPlusMargin: { marginTop: 0, fontSize: '1.8rem', marginBottom: 15, textAlign: 'center' },
	h6: { fontSize: '1.0rem', textAlign: 'center' },
	width300: { width: '300px' },
	gridMargin: { width: 300, margin: 'auto' },
	submitBtn: { width: 300, margin: '20px' },
	input: {
		backgroundColor: 'white',
		color: 'black',
		border: 'white',
		outline: 'white',
	},
	marginBottom30: { marginBottom: '30px' },
	linearProgress: { width: '80%', display: 'block', textAlign: 'center', margin: '20px auto 0px auto' },
	textCenter: { textAlign: 'center' },
}));

export function PasswordForgot() {
	const classes = useStyles();

	const [status, setStatus] = useState<boolean | null>(null);

	const [emailBad, setEmailBad] = useState(false);

	const [tryingReset, setTryingReset] = useState(false);

	const [loginEmail, setLoginEmail] = useState('');

	useEffect(() => {
		document.title = 'Patient password forgot - Rocket Doctor';
	}, []); //html page title

	const passwordForgotRequest = useCallback(async () => {
		setStatus(null);
		setTryingReset(true);

		try {
			await post(`apiv2/pt/password-forgot`, { email: loginEmail });
			setStatus(true);
		} catch (error) {
			setStatus(true);
		}

		setTryingReset(false);
	}, [loginEmail]);

	return (
		<form>
			<Box display="grid" justifyItems="center" gap={2}>
				<Box>
					<RDLogoLight />
				</Box>

				<Box display="grid" justifyItems="center">
					<Typography gutterBottom variant="h4" className={classes.textPlusMargin}>
						Forgot Password
					</Typography>

					<Typography gutterBottom variant="h6" className={classes.h6}>
						{status
							? 'Thank you, we sent you an email to reset your password'
							: 'Enter the email address associated with your account and we will send you a link to reset your password.'}
					</Typography>
				</Box>

				{!status && !tryingReset && (
					<>
						<Box display="grid" justifyItems="center">
							<FormControl className={classes.width300}>
								<TextField
									required
									id="email"
									key="email"
									type="email"
									margin="normal"
									fullWidth={true}
									variant="outlined"
									label="Enter Email"
									value={loginEmail}
									disabled={tryingReset}
									error={Boolean(status || emailBad)}
									onChange={(event) => {
										setEmailBad(!validateEmail(event.target.value));
										setLoginEmail(event.target.value);
									}}
								/>
							</FormControl>
						</Box>

						<LoadingButton
							color="primary"
							variant="contained"
							loading={tryingReset}
							endIcon={<LockIcon />}
							loadingPosition="center"
							className={classes.submitBtn}
							onClick={() => passwordForgotRequest()}
							disabled={!emailBad && loginEmail !== '' ? false : true}
						>
							Continue
						</LoadingButton>

						{!tryingReset ? (
							<Typography gutterBottom variant="body2" className={classes.textCenter}>
								Return to <Link href="/src/pages/Auth/login">SIGN IN</Link>
							</Typography>
						) : null}
					</>
				)}
			</Box>
		</form>
	);
}
